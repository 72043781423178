import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyD74_O3tsCMbOrqb98L8-FEDoOaRimcCn0",
  authDomain: "calvano-millbrae-station.firebaseapp.com",
  databaseURL: "https://calvano-millbrae-station.firebaseio.com",
  projectId: "calvano-millbrae-station",
  storageBucket: "calvano-millbrae-station.appspot.com",
  messagingSenderId: "591057808835"
};
firebase.initializeApp(config);
export default firebase;
